import React from 'react';
import './pulsating.scss';

const PulsatingIcon = () => (
  <>
    <div className="pulsating" />
  </>
);

export default PulsatingIcon;
